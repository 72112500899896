import { getRequest,postRequest,urlTitle } from "@/utils/request";
import qs from "qs";
window.qs = qs;
// 事件记录
export const eventApi = {
  // 操作日志
  queryOperation: (query) => {
    return getRequest(`${urlTitle}/operation/log/list`, query);
  },
    // 操作日志
    deleteOperation: (id) => {
      return postRequest(`${urlTitle}/operation/log/${id}`);
    },
  // 平台运行日志
  queryPlatform: (query) => {
    return getRequest(`${urlTitle}/execution/log/list`, query);
  },
  // 平台运行日志
  deletePlatform: (id) => {
    return postRequest(`${urlTitle}/execution/log/${id}`);
  },
};
